import p13 from '../Images/block-3/evolve-b3-p13.jpg'
import p14 from '../Images/block-3/evolve-b3-p14.jpg'
import p15 from '../Images/block-3/evolve-b3-p15.jpg'
import Carousel from '../Carousel/Carousel';

const Color = () => {
    return (
        <Carousel images={[
            { src: p13, title: 'jug, pear, mushroom' },
            { src: p14, title: 'pepper, lipstick, checkbook' },
            { src: p15, title: 'pawn, cup, pear' },
        ]} />
    );
}

export default Color;